import { navigateToUrl } from 'single-spa';

export const navigateWithParams = (url: string, extraParams?: string): void => {
  const queryParams = window.location.search;
  let goToUrl = url + (queryParams || '?');
  if (extraParams && extraParams.startsWith('&'))
    goToUrl = goToUrl + extraParams;
  else if (extraParams) goToUrl = `${goToUrl}&${extraParams}`;
  navigateToUrl(goToUrl);
};
