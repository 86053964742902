import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getQueryParams } from '@creditasmx-utilities/helpers';

const tokenApi = localStorage.getItem('affiliate_access_token');
const authorization = `Bearer ${tokenApi}` || '';

const axiosRequestConfiguration = (endpoint: string): AxiosRequestConfig => ({
	baseURL: `${process.env.REACT_APP_AUTO_ORCHESTRATOR_URL || ''}/${endpoint}`,
	responseType: 'json',
	headers: {
		'Content-Type': 'application/json',
		Authorization: authorization,
	},
});

export const getSteps = (): AxiosInstance => {
	const queryParams = getQueryParams();
	
	const privateLeadId =
		queryParams.get('sessionId') || localStorage.getItem('lead_id');
	const endpoint = `leads/${privateLeadId}/steps`;
	
	return Axios.create({
		...axiosRequestConfiguration(endpoint),
		method: 'GET',
	});
};
