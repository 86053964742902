import { navigateWithParams } from './utils/url/url';
import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';

const prefix = (location: Location, ...prefixes: string[]): boolean =>
  prefixes.some(
    (prefix) =>
      location.pathname === prefix || location.pathname === `${prefix}/`,
  );

const mfAutoForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO);

const mfFormBuroQuery = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO);

const mfAddressForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.ADDRESS_INFO);

const mfNip = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.NIP);

const mfDocumentsForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS);

const mfFinishPin = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.FINISH);

const mfFormIncome = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME);

const mfPreQualification = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.PRE_QUALIFICATION);

const mfExtraInfo = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.EXTRA_INFO);

const mfDealerTable = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.DEALER_LEADS);

const mfRejectedPage = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS);

const mfRejectedThirtyDays = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS);

const mfIdentityVerification = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION);

const mfLinkToDealersTable = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.LINK_TO_DEALERS_TABLE);

const mfSentDocuments = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.SENT_DOCUMENTS);

const receivedRequest = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST);

const mfPartnerInfoReceived = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.PARTNER_INFO_RECEIVED);

const mfNavbar = (location: Location): boolean =>
  location.pathname !== BaseRoutes.AUTOFIN_AUTO + ROUTES.LOGIN;

const mfStepper = (location: Location): boolean =>
  prefix(
    location,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.ADDRESS_INFO,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.NIP,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.EXTRA_INFO,
    BaseRoutes.AUTOFIN_AUTO + ROUTES.REFERENCES,
  );

const mfLogin = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.LOGIN);

const mfPartnerForm = (location: Location): boolean =>
  prefix(location, BaseRoutes.AUTOFIN_AUTO + ROUTES.PARTNERS);

// We can add an auto redirect using navigateWithParams
const main = (location: Location): boolean => {
  if (
    location.pathname === BaseRoutes.AUTOFIN_AUTO ||
    location.pathname ===
      BaseRoutes.AUTOFIN_AUTO.substr(0, BaseRoutes.AUTOFIN_AUTO.length - 1) ||
    location.pathname === '/'
  )
    navigateWithParams(BaseRoutes.AUTOFIN_AUTO + ROUTES.LOGIN);
  return true;
};

const notFound = (location: Location): boolean => {
  const isCurrentPathInRoutes = Object.values(ROUTES).some(
    (route) =>
      BaseRoutes.AUTOFIN_AUTO + route === location.pathname ||
      BaseRoutes.AUTOFIN_AUTO + route + '/' === location.pathname,
  );
  return !isCurrentPathInRoutes;
};

export default {
  mfAutoForm,
  mfFormBuroQuery,
  mfAddressForm,
  mfNip,
  mfDocumentsForm,
  mfNavbar,
  notFound,
  mfStepper,
  mfLogin,
  main,
  mfFinishPin,
  mfFormIncome,
  mfPreQualification,
  mfExtraInfo,
  mfDealerTable,
  mfRejectedPage,
  mfRejectedThirtyDays,
  mfIdentityVerification,
  mfLinkToDealersTable,
  mfSentDocuments,
  receivedRequest,
  mfPartnerInfoReceived,
  mfPartnerForm,
};
