import { EventManager } from '@creditas/event-manager';
import { BaseRoutes } from './baseRoutes';
import { ROUTES } from './routes';
export const NavLinks = [
  {
    text: 'Nueva solicitud',
    link: BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
    callback: (): void => {
      localStorage.removeItem('creditasPersonPageValues');
      localStorage.removeItem('lead_id');
      EventManager.publish({
        application: 'AUTO_NAV',
        name: 'NAVBAR',
        type: 'NEW_LEAD',
      });
    },
  },
  {
    text: 'Solicitudes',
    link: BaseRoutes.AUTOFIN_AUTO + ROUTES.DEALER_LEADS,
    showActiveStatus: true,
  },
];

export const LOGO_GO_TO = '';
