import {BaseRoutes} from "./baseRoutes";
import {ROUTES} from "./routes";
import {getSteps} from "./microfrontends/shared/requests/stepper.api";

interface IndexableMf {
  NOT_FOUND: string;
  MATI: string;
  RECEIVED_REQUEST: string;
  STEPPER: string;

  [key: string]: string;
}

export const MFs: IndexableMf = {
  NOT_FOUND: `https://assets.creditas.com/mx-notfound-mf/${process.env.REACT_APP_BUILD_ENV}/creditasmx-notfound-mf.js`,
  MATI: `https://assets.creditas.com/mx-mati-verification-mf/${process.env.REACT_APP_BUILD_ENV}/mx-mati-verification-mf.js`,
  RECEIVED_REQUEST: `https://assets.creditas.com/mx-received-request-mf/${process.env.REACT_APP_BUILD_ENV}/mx-received-request-mf.js`,
  STEPPER: `https://assets.creditas.com/mx-stepper-mf/${process.env.REACT_APP_BUILD_ENV}/mx-stepper-mf.js`,
  PRECALIFICATION: `https://assets.creditas.com/mx-precalification-mf/${process.env.REACT_APP_BUILD_ENV}/mx-precalification-mf.js`,
  REJECTED: `https://assets.creditas.com/mx-rejected-page-mf/${process.env.REACT_APP_BUILD_ENV}/mx-rejected-page-mf.js`,
};

Object.keys(MFs).forEach((key) => {
  MFs[key] = MFs[key] + `?v=${Math.random()}`;
});

export const receivedRequestProps ={
  goTo: {
    toContinue: BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS
  },
  productName: 'autofin',
  domElementId: 'renderContainerId',
  getLeadId: () => localStorage.getItem('lead_id'),
  personalDocsToShow: ['INE', 'PROOF_INCOME', 'PROOF_ADDRESS'],
  loadingSteps: false
}

export const getReceivedRequestProps = () => {
  // Because customProps is not a promise function we can use reference value to update it
  if(!receivedRequestProps.loadingSteps){
    receivedRequestProps.loadingSteps = true;
    getSteps().request({}).then((res)=> {
      if(res.data?.find( (step: { id: string; }) => step.id == 'verificacion-identidad' )){
        receivedRequestProps.goTo.toContinue = BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION;
      }else {
        receivedRequestProps.goTo.toContinue = BaseRoutes.AUTOFIN_AUTO + ROUTES.DOCUMENTS;
      }
    }).catch(() => {
      receivedRequestProps.loadingSteps = false
    })
  }
  return receivedRequestProps;
}
