import { start, registerApplication, LifeCycles } from 'single-spa';
import { getQueryParams } from '@creditasmx-utilities/helpers';
import Activity from './activityFns';
import GO_TO_ROUTES, { ROUTES, PUBLIC_ROUTES } from './routes';
import { NavLinks, LOGO_GO_TO } from './navbarItems';
import { BaseRoutes } from './baseRoutes';
import {getReceivedRequestProps, MFs} from './microfrontendRegisters';

const sessionId = getQueryParams().get('sessionId');

const registerApps = (): void => {
  registerApplication(
    'main',
    () => import('./microfrontends/app.lifecycles'),
    Activity.main,
  );
  registerApplication(
    'notFound',
    () =>
      System.import(MFs.NOT_FOUND) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.notFound,
    {
      domElementId: 'renderContainerId',
      product: {
        name: 'Auto',
        landingPageURL: process.env.REACT_APP_CREDITAS_AUTOFIN_LANDING_URL,
      },
    },
  );
  registerApplication(
    'mfPersonForm',
    () => import('./microfrontends/mfFormBuroQuery/mfFormBuroQuery.lifecycle'),
    Activity.mfFormBuroQuery,
    {
      goTo: GO_TO_ROUTES.PERSONAL_INFO,
    },
  );
  registerApplication(
    'mfAddressForm',
    () => import('./microfrontends/mfAddressForm/mfAddressform.lifecycle'),
    Activity.mfAddressForm,
    {
      goTo: GO_TO_ROUTES.ADDRESS_INFO,
    },
  );
  registerApplication(
    'mfAutoForm',
    () => import('./microfrontends/mfAutoForm/mfAutoForm.lifecycle'),
    Activity.mfAutoForm,
    {
      goTo: GO_TO_ROUTES.AUTO,
    },
  );
  registerApplication(
    'mfFormIncome',
    () => import('./microfrontends/mfFormIncome/mfFormIncome.lifecycle'),
    Activity.mfFormIncome,
    {
      goTo: GO_TO_ROUTES.INCOME,
    },
  );
  registerApplication(
    'mfFormNip',
    () => import('./microfrontends/mfFormPin/mfFormPin.lifecycle'),
    Activity.mfNip,
    {
      goTo: GO_TO_ROUTES.NIP,
    },
  );
  registerApplication(
    'mfDocumentsForm',
    () => import('./microfrontends/mfDocumentsForm/mfDocumentsForm.lifecycle'),
    Activity.mfDocumentsForm,
    {
      goTo: BaseRoutes.AUTOFIN_AUTO + GO_TO_ROUTES.DOCUMENTS,
    },
  );
  registerApplication(
    'mfNavbar',
    () => import('./microfrontends/mfNavbar/mfNavbar.lifecycle'),
    Activity.mfNavbar,
    {
      links: NavLinks,
      logoGoTo: BaseRoutes.AUTOFIN_AUTO + LOGO_GO_TO,
      baseUrl: BaseRoutes.AUTOFIN_AUTO,
      loginUrl: ROUTES.LOGIN,
      publicUrls: PUBLIC_ROUTES,
    },
  );
  registerApplication(
    'mfStepper',
    () =>
      System.import(MFs.STEPPER) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfStepper,
    {
      baseUrl: BaseRoutes.AUTOFIN_AUTO,
      domElementId: 'renderStepperId',
      leadIdKey: 'lead_id',
      tokenKey: 'affiliate_access_token',
      apiUrl: process.env.REACT_APP_AUTO_ORCHESTRATOR_URL + '/',
    },
  );
  registerApplication(
    'mfLogin',
    () => import('./microfrontends/mfLogin/msLogin.lifecycle'),
    Activity.mfLogin,
    {
      goTo: BaseRoutes.AUTOFIN_AUTO + GO_TO_ROUTES.LOGIN,
    },
  );
  registerApplication(
    'mfFinishPin',
    () => import('./microfrontends/mfFinishPin/mfFinishPin.lifecycle'),
    Activity.mfFinishPin,
    {
      goTo: BaseRoutes.AUTOFIN_AUTO + GO_TO_ROUTES.FINISH,
    },
  );
  registerApplication(
    'mfPreQualification',
    () =>
      System.import(MFs.PRECALIFICATION) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfPreQualification,
    {
      goTo: GO_TO_ROUTES.PRE_QUALIFICATION,
      domElementId: 'renderContainerId',
      productName: 'autofin',
      apiUrl: process.env.REACT_APP_AUTO_ORCHESTRATOR_URL + '',
      tokenKey: 'affiliate_access_token',
      getLeadId: () => sessionId || localStorage.getItem('lead_id'),
    },
  );
  registerApplication(
    'mfExtraInfo',
    () => import('./microfrontends/mfExtraInfoForm/mfExtraInfoForm.lifecycle'),
    Activity.mfExtraInfo,
    {
      goTo: GO_TO_ROUTES.EXTRA_INFO,
    },
  );
  registerApplication(
    'mfDealerTable',
    () => import('./microfrontends/mfDealerTable/mfDealerTable.lifecycle'),
    Activity.mfDealerTable,
    {
      goTo: GO_TO_ROUTES.DEALER_LEADS,
    },
  );
  registerApplication(
    'mfRejectedThirtyDays',
    () =>
      import(
        './microfrontends/mfRejectedThirtyDays/mfRejectedThirtyDays.lifecycle'
      ),
    Activity.mfRejectedThirtyDays,
    {
      goTo: BaseRoutes.AUTOFIN_AUTO + ROUTES.PERSONAL_INFO,
    },
  );
  registerApplication(
    'mfRejectedPage',
    () =>
      System.import(MFs.REJECTED) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.mfRejectedPage,
    {
      domElementId: 'renderContainerId',
      productName: 'gaAutofinMX',
      goTo: GO_TO_ROUTES.REJECTED_REASONS,
      getLeadId: () => localStorage.getItem('lead_id'),
    },
  );
  registerApplication(
    'mfIdentityVerification',
    () => System.import(MFs.MATI) as Promise<LifeCycles<{}>>,
    Activity.mfIdentityVerification,
    {
      domElementId: 'renderContainerId',
      productName: 'autofin',
      productType: 'MX_AUTO_FIN',
      clientId: process.env.REACT_APP_MATI_CLIENT_ID,
      flowId: process.env.REACT_APP_MATI_FLOW_ID,
      goTo: BaseRoutes.AUTOFIN_AUTO + GO_TO_ROUTES.IDENTITY_VERIFICATION,
      getLeadId: () => localStorage.getItem('lead_id'),
      containerProps: {
        marginTop: '5%',
      },
    },
  );

  registerApplication(
    'mfLinkToDealersTable',
    () =>
      import(
        './microfrontends/mfLinkToDealersTable/mfLinkToDealersTable.lifecycle'
      ),
    Activity.mfLinkToDealersTable,
    { goTo: GO_TO_ROUTES.LINK_TO_DEALERS_TABLE },
  );

  registerApplication(
    'mfsentDocuments',
    () => import('./microfrontends/mfSentDocuments/mfSentDocuments.lifecycle'),
    Activity.mfSentDocuments,
    {
      goTo: BaseRoutes.AUTOFIN_AUTO + GO_TO_ROUTES.SENT_DOCUMENTS,
    },
  );

  registerApplication(
    'receivedRequest',
    () =>
      System.import(MFs.RECEIVED_REQUEST) as Promise<
        LifeCycles<Record<string, unknown>>
      >,
    Activity.receivedRequest,
    getReceivedRequestProps
  );

  registerApplication(
    'mfDataReceived',
    () => import('./microfrontends/mfDataReceived/mfDataReceived.lifecycle'),
    Activity.mfPartnerInfoReceived,
    {
      goTo: GO_TO_ROUTES.DATA_RECEIVED,
    },
  );
  registerApplication(
    'mfPartnerForm',
    () => import('./microfrontends/mfPartnerForm/mfPartnerForm.lifecycle'),
    Activity.mfPartnerForm,
    {
      goTo: BaseRoutes.AUTOFIN_AUTO + GO_TO_ROUTES.PARTNERS,
    },
  );
};

registerApps();
start();

start({
  urlRerouteOnly: true,
});
