// @ts-nocheck
import { BaseRoutes } from './baseRoutes';

export enum ROUTES {
  LOGIN = 'iniciar-sesion',
  PERSONAL_INFO = 'datos-personales',
  AUTO = 'vehiculo',
  INCOME = 'ingresos',
  PRE_QUALIFICATION = 'pre-calificacion',
  NIP = 'autorizacion',
  FINISH = 'finalizar',
  DOCUMENTS = 'documentos',
  EXTRA_INFO = 'datos-vehiculo',
  DEALER_LEADS = 'solicitudes',
  IDENTITY_VERIFICATION = 'verificacion-identidad',
  REJECTED_THIRTY_DAYS = 'solicitud-existente',
  REJECTED_REASONS = 'razones-de-rechazo',
  LINK_TO_DEALERS_TABLE = 'link-a-solicitudes',
  SENT_DOCUMENTS = 'documentos-enviados',
  RECEIVED_REQUEST = 'sin-preaprobacion',
  REFERENCES = 'referencias',
  PARTNERS = 'datos-socio',
  PARTNER_INFO_RECEIVED = 'datos-socio-recibidos',
  ADDRESS_INFO = 'domicilio',
}

const GO_TO_ROUTES_STG = {
  LOGIN: ROUTES.PERSONAL_INFO,
  PERSONAL_INFO: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.ADDRESS_INFO,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  ADDRESS_INFO: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.AUTO,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  AUTO: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.INCOME,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  INCOME: {
    goToSuccess: BaseRoutes.AUTOFIN_AUTO + ROUTES.NIP,
    goToRejectedDays: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_THIRTY_DAYS,
    goToRejectedReasons: BaseRoutes.AUTOFIN_AUTO + ROUTES.REJECTED_REASONS,
  },
  NIP: {
    approved: BaseRoutes.AUTOFIN_AUTO + ROUTES.PRE_QUALIFICATION,
    denied: BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST,
  },
  PRE_QUALIFICATION: BaseRoutes.AUTOFIN_AUTO + ROUTES.RECEIVED_REQUEST,
  IDENTITY_VERIFICATION: ROUTES.DOCUMENTS,
  DOCUMENTS: ROUTES.EXTRA_INFO,
  EXTRA_INFO: {
    withDocuments: BaseRoutes.AUTOFIN_AUTO + ROUTES.SENT_DOCUMENTS,
    withoutDocuments: BaseRoutes.AUTOFIN_AUTO + ROUTES.LINK_TO_DEALERS_TABLE,
  },
  FINISH: ROUTES.PERSONAL_INFO,
  DEALER_LEADS: {
    confirmed: ROUTES.PRE_QUALIFICATION,
    notConfirmed: ROUTES.PERSONAL_INFO,
  },
  LINK_TO_DEALERS_TABLE: {
    toRequestTable: BaseRoutes.AUTOFIN_AUTO + ROUTES.DEALER_LEADS,
    toFinish: BaseRoutes.AUTOFIN_AUTO + ROUTES.FINISH,
  },
  RECEIVED_REQUEST: {
    toContinue: BaseRoutes.AUTOFIN_AUTO + ROUTES.IDENTITY_VERIFICATION,
    toExit: BaseRoutes.AUTOFIN_AUTO + ROUTES.LINK_TO_DEALERS_TABLE,
  },
  SENT_DOCUMENTS: ROUTES.FINISH,
  REFERENCES: ROUTES.IDENTITY_VERIFICATION,
  PARTNERS: ROUTES.PARTNER_INFO_RECEIVED,
  DATA_RECEIVED: 'https://creditas-mx-landings.stg.creditas.io/',
  REJECTED_REASONS: 'https://creditas-mx-landings.stg.creditas.io/',
};

const GO_TO_ROUTES_PROD = {
  ...GO_TO_ROUTES_STG,
  DATA_RECEIVED: 'https://www.creditas.mx/',
  REJECTED_REASONS: 'https://www.creditas.mx/',
};

const GO_TO_ROUTES =
  process.env.REACT_APP_BUILD_ENV === 'production'
    ? GO_TO_ROUTES_PROD
    : GO_TO_ROUTES_STG;

export const PUBLIC_ROUTES = [
  ROUTES.LOGIN,
  ROUTES.PARTNERS,
  ROUTES.PARTNER_INFO_RECEIVED,
];

export default GO_TO_ROUTES;
